import FeatureGates from '@atlaskit/feature-gate-js-client';

import { COHORTS, type CohortValues, type ExperimentNameValues } from '../utils';

type ExperimentEnrollment = {
	cohort: CohortValues; // temp while we figure out a better way
	isTreatment: boolean;
	isControl: boolean;
	isUnenrolled: boolean;
	isEnrolled: boolean;
};

/**
 * This hook is used to determine the enrollment status of the user in the experiment.
 * It returns the user's cohort and whether they are in the treatment, control, unenrolled, or enrolled group.
 *
 * @param {ExperimentNameValues} experimentName - The name of the experiment.
 * @returns {ExperimentEnrollment} - The user's cohort and enrollment status.
 */
export const useExperimentEnrollment = (
	experimentName: ExperimentNameValues,
): ExperimentEnrollment => {
	const cohort = FeatureGates.getExperimentValue<CohortValues>(
		experimentName,
		'cohort',
		COHORTS.NOT_ENROLLED,
		{ fireExperimentExposure: false },
	);

	return {
		cohort,
		isTreatment: cohort === COHORTS.TREATMENT,
		isControl: cohort === COHORTS.CONTROL,
		isUnenrolled: cohort === COHORTS.NOT_ENROLLED,
		isEnrolled: cohort === COHORTS.TREATMENT || cohort === COHORTS.CONTROL,
	};
};
